const IsPropNumeric = (prop) => {
  return (
    prop.type === 'float' ||
    prop.type === 's8' ||
    prop.type === 'u8' ||
    prop.type === 's16' ||
    prop.type === 'u16' ||
    prop.type === 's32' ||
    prop.type === 'u32' ||
    prop.type === 's64' ||
    prop.type === 'u64' ||
    prop.type === 'percentage' ||
    prop.type === 'difftime'
  )
}

export default IsPropNumeric
