import { createTheme } from '@material-ui/core/styles'

const theme = createTheme({
  palette: {
    primary: { main: '#2075BC', light: '#2BACE2' },
    secondary: { main: '#F4F4F4' },
  },
})

export default theme
