import useStore from '../store/store'

const GetPhoneCode = (iso2) => {
  const countries = useStore((state) => state.countries)
  var phoneCode = ''
  const index = countries.findIndex((country) => country.Iso2 === iso2)
  if (index !== -1) {
    phoneCode = countries[index].PhoneCode
  }
  return phoneCode
}

export default GetPhoneCode
