import React, { useState } from 'react'

// Libraries
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import { CircularProgress, TextField } from '@material-ui/core'
import * as Yup from 'yup'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import { Formik, Form, Field } from 'formik'
import { Link, Navigate } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'

// API
import api from '../../api/api'

// Store
import useStore from '../../store/store'

// Consts
import ErrorCodes from '../../consts/ErrorCodes'

import resendConfirmationCode from '../../actions/resendConfirmationCode'

const ConfirmSchema = Yup.object().shape({
  code: Yup.string().required('Required'),
})

const setErrorMessage = (errCode) => {
  switch (errCode) {
    case ErrorCodes.already_confirmed:
      return 'Phone already confirmed!'
    case ErrorCodes.too_many_attempts:
      return 'Too many attempts!'
    default:
      return 'Incorrect SMS code!'
  }
}

export default function RegisterFormPage() {
  // State.
  const formError = useStore((state) => state.formError)
  const code = ''
  const loading = useStore((state) => state.loading)
  const setFormError = useStore((state) => state.setFormError)
  const setLoading = useStore((state) => state.setLoading)
  const [confirmed, setConfirmed] = useState(false)

  const confirm = async ({ code }) => {
    console.log('Calling confirm')
    // Modify state, so the button knows that we're requesting data.
    setLoading(true)

    try {
      // Call API.
      const response = await api.post('/security/confirm', {
        code,
      })

      // Deconstruct response.data.
      const { success, errCode } = response.data

      if (success) {
        // Everything done, so stop loading.
        setLoading(false)
        setConfirmed(true)
      } else if (!success && errCode) {
        setLoading(false)
        setFormError(errCode)
      }
    } catch (e) {
      console.log(e)
      // Network Error (error code 17)
      if (e.code) setFormError(e.code)
    }
  }

  // Styles.
  const classes = useStyles()

  // If we have successfully confirmed the phone number, then redirect to update-email.
  if (confirmed) {
    return <Navigate to='/update-email' />
  }

  return (
    <Container component='main' maxWidth='xs'>
      <CssBaseline />
      <div className={classes.paper}>
        <Typography component='h1' variant='h5'>
          Confirm phone number
        </Typography>
        <Formik
          className={classes.form}
          initialValues={{
            code: code,
          }}
          onSubmit={confirm}
          validationSchema={ConfirmSchema}
          validateOnBlur
          validateOnChange
        >
          {({ values, errors, touched, handleChange, handleBlur }) => (
            <Form>
              <Field
                component={TextField}
                value={values.code}
                disabled={loading}
                error={errors.code && touched.code}
                fullWidth
                helperText={errors.code && touched.code ? errors.code : ''}
                id='code'
                label='SMS code'
                margin='normal'
                name='code'
                onBlur={handleBlur}
                onChange={(value) => {
                  formError && setFormError(null)
                  handleChange(value)
                }}
                size='small'
                type='text'
                variant='outlined'
              />
              {formError && (
                <Typography
                  align='center'
                  className={classes.errorMessage}
                  color='error'
                  variant='body1'
                >
                  {setErrorMessage(formError)}
                </Typography>
              )}
              <div className={classes.wrapper}>
                <Button
                  color='primary'
                  disabled={loading}
                  fullWidth
                  type='submit'
                  variant='contained'
                >
                  Confirm
                </Button>
                {loading && (
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                  />
                )}
              </div>
              <Link onClick={resendConfirmationCode}>
                <Typography align='center' color='primary' variant='body1'>
                  Resend SMS
                </Typography>
              </Link>
            </Form>
          )}
        </Formik>
      </div>
    </Container>
  )
}

const useStyles = makeStyles((theme) => ({
  buttonProgress: {
    color: 'primary',
    left: '50%',
    marginLeft: -12,
    marginTop: -12,
    position: 'absolute',
    top: '50%',
  },
  errorMessage: {
    marginTop: theme.spacing(2),
  },
  form: {
    marginTop: theme.spacing(1),
    width: '100%', // Fix IE 11 issue.
  },
  paper: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(8),
  },
  wrapper: {
    margin: theme.spacing(3, 0, 2),
    position: 'relative',
  },
}))
