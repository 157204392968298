import { sendRequest } from '../api/websocketApi'

const Unsubscribe = () => {
  sendRequest(
    {
      endpoint: '/user/unsubscribe/network-update',
    },
    undefined,
    { subscription: 'user-network-update', cancel: true }
  )
}

export default Unsubscribe
