import React, { useState, useEffect } from 'react'

// MUI components
import { Switch } from '@material-ui/core'

// Actions
import setGatewayPropertyValue from '../actions/setGatewayPropertyValue'
import setNodePropertyValue from '../actions/setNodePropertyValue'
import setSensorPropertyValue from '../actions/setSensorPropertyValue'

const SavingSwitch = ({
  prop,
  nodeUuid = null,
  index = null,
  gatewayUuid = null,
}) => {
  // Local state
  const [disabled, setDisabled] = useState(false)
  const [timeoutHandle, setTimeoutHandle] = useState(null)

  useEffect(() => {
    if (disabled) {
      setDisabled(false)

      if (timeoutHandle) {
        clearTimeout(timeoutHandle)
        setTimeoutHandle(null)
      }
    }
    // eslint-disable-next-line
  }, [prop.v])

  useEffect(() => {
    if (disabled) {
      const timeout = setTimeout(() => {
        setDisabled(false)
      }, 1000)
      setTimeoutHandle(timeout)

      return () => {
        clearTimeout(timeoutHandle)
        setTimeoutHandle(null)
      }
    }
    // eslint-disable-next-line
  }, [disabled])

  // Handlers.
  const handleChange = (event) => {
    setDisabled(true)
    if (nodeUuid && index) {
      setSensorPropertyValue(nodeUuid, index, prop.name, event.target.checked)
    } else if (nodeUuid) {
      setNodePropertyValue(nodeUuid, prop.name, event.target.checked)
    } else if (gatewayUuid) {
      setGatewayPropertyValue(gatewayUuid, prop.name, event.target.checked)
    }
  }

  return (
    <Switch
      checked={prop.v}
      color='primary'
      disabled={disabled}
      edge='end'
      onChange={handleChange}
    />
  )
}

export default React.memo(SavingSwitch)
