const IsPermissionGranted = (permission) => {
  try {
    const permissions = localStorage.getItem('permissions')
    if (!permissions) return false
    return JSON.parse(permissions).includes(permission)
  } catch (e) {
    console.error(e)
    return false
  }
}

export default IsPermissionGranted
