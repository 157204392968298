import React, { useState } from 'react'

// MUI Components
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Menu,
  MenuItem,
  TextField,
  makeStyles,
} from '@material-ui/core'
import MoreVertIcon from '@material-ui/icons/MoreVert'

// Actions
import unassignGateway from '../actions/unassignGateway'
import renameGateway from '../actions/renameGateway'

// Helpers
import isPermissionGranted from '../helpers/isPermissionGranted'

// Consts
import Permissions from '../consts/Permissions'

const GatewayMenu = ({ gatewayId, name }) => {
  // Styles
  const classes = useStyles()

  const [anchor, setAnchor] = useState()
  const [renameDialogOpen, setRenameDialogOpen] = useState(false)
  const [newName, setNewName] = useState(name || '')
  const [removeDialogOpen, setRemoveDialogOpen] = useState(false)

  const handleClick = (event) => {
    setAnchor(event.currentTarget)
  }

  const handleClose = () => {
    setAnchor(null)
  }

  const handleRenameClick = () => {
    setRenameDialogOpen(true)
    setAnchor(null)
  }

  const handleRemoveClick = () => {
    setRemoveDialogOpen(true)
    setAnchor(null)
  }

  const handleRemoveDialogCancel = () => {
    setRemoveDialogOpen(false)
  }

  const handleRenameDialogCancel = () => {
    setRenameDialogOpen(false)
  }

  const handleRenameDialogSave = () => {
    renameGateway(gatewayId, newName)
    setRenameDialogOpen(false)
  }

  return (
    <>
      <IconButton
        className={classes.menuButton}
        aria-label='more'
        aria-controls='long-menu'
        aria-haspopup='true'
        onClick={handleClick}
        color='primary'
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id='simple-menu'
        anchorEl={anchor}
        keepMounted
        open={Boolean(anchor)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleRenameClick}>Rename...</MenuItem>
        {isPermissionGranted(Permissions.remove_gateway) && <MenuItem onClick={handleRemoveClick}>Remove...</MenuItem>}
      </Menu>

      {/* RENAME */}
      <Dialog
        open={renameDialogOpen}
        onClose={handleRenameDialogCancel}
        aria-labelledby='form-dialog-title'
      >
        <DialogTitle id='form-dialog-title'>Rename</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            fullWidth
            id='name'
            label='Name'
            margin='dense'
            onChange={(event) => {
              setNewName(event.target.value)
            }}
            type='text'
            value={newName}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleRenameDialogCancel} color='primary'>
            Cancel
          </Button>
          <Button onClick={handleRenameDialogSave} color='primary'>
            Save
          </Button>
        </DialogActions>
      </Dialog>

      {/* REMOVE */}
      <Dialog
        open={removeDialogOpen}
        onClose={handleRemoveDialogCancel}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>Remove gateway</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            Are you sure you want to remove this gateway?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleRemoveDialogCancel} color='primary'>
            No
          </Button>
          <Button
            onClick={() => unassignGateway(gatewayId)}
            color='primary'
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default GatewayMenu

const useStyles = makeStyles((theme) => ({
  menuButton: {
    '&:hover': {
      color: theme.palette.primary.light,
      backgroundColor: 'transparent',
    },
  },
}))
