import { produce } from 'immer'
import useStore from '../store/store'
import { sendRequest } from '../api/websocketApi'

const DeleteLogic = (userNetworkLogicId) => {
  const setFormError = useStore.getState().setFormError
  const setLoading = useStore.getState().setLoading
  const logics = useStore.getState().logics
  const setLogics = useStore.getState().setLogics
  const setIsAddLogicFormVisible = useStore.getState().setIsAddLogicFormVisible

  setLoading(true)
  sendRequest(
    {
      endpoint: '/network-logic/delete',
      body: { userNetworkLogicId },
    },
    (data) => {
      const { success, errCode } = data
      if (success) {
        const newLogics = produce(logics, (draft) => {
          const index = draft.findIndex(
            (logic) => logic.UserNetworkLogicId === userNetworkLogicId
          )
          if (index !== -1) {
            draft.splice(index, 1)
          }
        })
        setLogics(newLogics)
        setLoading(false)
        setIsAddLogicFormVisible(false)
        window.location.reload(false)
      } else if (!success && errCode) {
        setLoading(false)
        setFormError(errCode)
      }
    }
  )
}

export default DeleteLogic
