// Store
import useStore from '../store/store'

const GetSensorModel = (sensorModelId) => {
  const sensorModels = useStore.getState().sensorModels
  if (sensorModels && sensorModels.length > 0) {
    const index = sensorModels.findIndex(
      (sensorModel) => sensorModel.SensorModelId === sensorModelId
    )
    if (index !== -1) {
      return sensorModels[index]
    }
  }
}

export default GetSensorModel
