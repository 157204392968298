import React, { useEffect } from 'react'

// Components
import Sensor from '../components/Sensor'

// Store
import useStore from '../store/store'

export default function SensorsPage() {
  const setTitle = useStore((state) => state.setTitle)
  const nodes = useStore((state) => state.nodes)

  useEffect(() => {
    setTitle('Attachments')
  })

  return nodes.map((node) =>
    node.sensors.map((sensor) => (
      <Sensor
        index={sensor.Index}
        key={sensor.Index}
        name={sensor.Name}
        nodeUuid={node.Uuid}
        nodeId={node.NodeId}
        sensorModelId={sensor.SensorModelId}
        nodeName={node.Name}
      />
    ))
  )
}
