import { sendRequest } from '../api/websocketApi'
import useStore from '../store/store'

const GetNetworkUpdateFull = async () => {
  const setLoading = useStore.getState().setLoading
  const setNetworkStateFetched = useStore.getState().setNetworkStateFetched
  const setNetworkStateCounter = useStore.getState().setNetworkStateCounter
  const setNetworkStateProperties =
    useStore.getState().setNetworkStateProperties
  const setGatewayUuid = useStore.getState().setGatewayUuid
  const setGatewayStatus = useStore.getState().setGatewayStatus
  const setFormError = useStore.getState().setFormError

  setLoading(true)
  sendRequest(
    {
      endpoint: '/user/get-network-update-full',
    },
    (data) => {
      const { success, errCode, errDetails, gateways } = data

      if (success && gateways.length > 0) {
        setNetworkStateProperties(gateways[0].properties)
        setNetworkStateCounter(gateways[0].counter)
        setGatewayUuid(gateways[0].uuid)
        setGatewayStatus(gateways[0].status)
        setNetworkStateFetched(true)
        setLoading(false)
      } else if (!success && errCode) {
        console.log(errCode + ' - ' + errDetails)
        setFormError(errCode)
        setLoading(false)
      }
    }
  )
}

export default GetNetworkUpdateFull
