import useStore from '../store/store'
import { sendRequest } from '../api/websocketApi'

const RevokeAPIKey = (nodeId) => {
  const setFormError = useStore.getState().setFormError
  const setSuccessMessage = useStore.getState().setSuccessMessage
  const setLoading = useStore.getState().setLoading
  const setAPIKey = useStore.getState().setAPIKey

  setLoading(true)
  sendRequest(
    {
      endpoint: '/user/revoke-external-api-key',
    },
    (data) => {
      const { success, errCode } = data
      if (success) {
        setAPIKey('')
        setLoading(false)
        setSuccessMessage('API key revoked!')
      } else if (!success && errCode) {
        setLoading(false)
        setFormError(errCode)
      }
    }
  )
}

export default RevokeAPIKey
