import useStore from '../store/store'
import { sendRequest } from '../api/websocketApi'
import moment from 'moment'

const SetSensorPropertyValue = (uuid, index, name, value) => {
  const setFormError = useStore.getState().setFormError
  const setLoading = useStore.getState().setLoading
  const setNetworkStateProperties =
    useStore.getState().setNetworkStateProperties

  setLoading(true)
  sendRequest(
    {
      endpoint: '/sensor/set-property-value',
      body: { uuid, index, name, value },
    },
    (data) => {
      const { success, errCode } = data
      if (success) {
        const newProperties = [
          {
            id: 's.' + uuid + '.' + index + '.' + name,
            v: value,
            t: moment().toISOString(),
          },
        ]
        setNetworkStateProperties(newProperties)
        setLoading(false)
      } else if (!success && errCode) {
        setLoading(false)
        setFormError(errCode)
      }
    }
  )
}

export default SetSensorPropertyValue
