import { produce } from 'immer'
import useStore from '../store/store'
import { sendRequest } from '../api/websocketApi'

const SetNodePropertyValue = (uuid, name, value) => {
  const setFormError = useStore.getState().setFormError
  const setLoading = useStore.getState().setLoading
  const networkState = useStore.getState().networkState
  const setNetworkState = useStore.getState().setNetworkState

  setLoading(true)
  sendRequest(
    {
      endpoint: '/node/set-property-value',
      body: { uuid, name, value },
    },
    (data) => {
      const { success, errCode } = data
      if (success) {
        const newNetworkState = produce(networkState, (draft) => {
          const nodeIndex = draft.nodes.findIndex((node) => node.uuid === uuid)
          if (nodeIndex !== -1) {
            const node = draft.nodes[nodeIndex]
            const propertyIndex = node.properties.findIndex(
              (prop) => prop.name === name
            )
            if (propertyIndex !== -1) {
              const property = node.properties[propertyIndex]
              property.v = value
            }
          }
        })
        setNetworkState(newNetworkState)
        setLoading(false)
      } else if (!success && errCode) {
        setLoading(false)
        setFormError(errCode)
      }
    }
  )
}

export default SetNodePropertyValue
