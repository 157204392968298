import React from 'react'

// MUI components
import { Box, Tooltip, Typography, makeStyles } from '@material-ui/core'

const SensorHeader = ({
  tooltip = '',
  name,
  caption = '',
  subtitle = '',
  icon,
  menuComponent,
}) => {
  // Styles
  const classes = useStyles()

  return (
    <Box className={classes.root}>
      <Tooltip arrow placement='left' title={tooltip}>
        <Box className={classes.textContainer}>
          <Typography variant='body1' className={classes.title}>
            {name}
          </Typography>
          <Typography variant='caption'>{caption}</Typography>
          <Typography variant='subtitle2'>{subtitle}</Typography>
        </Box>
      </Tooltip>
      <Box className={classes.iconContainer}>{icon}</Box>
      <Box className={classes.menuContainer}>{menuComponent}</Box>
    </Box>
  )
}

export default React.memo(SensorHeader)

const useStyles = makeStyles((theme) => ({
  iconContainer: {
    flexShrink: 0,
  },
  menuContainer: {
    flexShrink: 0,
  },
  root: {
    // Figma
    display: 'flex',
    padding: '16px 24px 20px 32px',
    justifyContent: 'space-between',
    alignItems: 'center',
    alignSelf: 'stretch',
    backgroundColor: theme.palette.secondary.main,
  },
  textContainer: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    flexGrow: 4,
  },
  title: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
}))
