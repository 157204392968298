import React, { useEffect, useState } from 'react';
import { produce } from 'immer';

// MUI components
import { Box, IconButton, Tooltip, makeStyles } from '@material-ui/core';

// MUI icons
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import WifiIcon from '@material-ui/icons/Wifi';
import WifiOffIcon from '@material-ui/icons/WifiOff';
import DoDisturbIcon from '@mui/icons-material/DoDisturb';

// Components
import ComponentHeader from './ComponentHeader';
import NodeMenu from './NodeMenu';
import SavingDatePicker from './SavingDateTimePicker';
import SavingSwitch from './SavingSwitch';
import SavingTextField from './SavingTextField';

// Store
import useStore from '../store/store';

// Helpers
import formatProp from '../helpers/formatProp';
import formatSerial from '../helpers/formatSerial';
import getNodeModel from '../helpers/getNodeModel';
import isPropNumeric from '../helpers/isPropNumeric';
import isPermissionGranted from '../helpers/isPermissionGranted.js';

// Consts
import Permissions from '../consts/Permissions';

const Node = ({ id, modelId, name, nodeSensors, uuid }) => {
  // Global state
  const networkStateFetched = useStore((state) => state.networkStateFetched);
  const networkStateProperties = useStore(
    (state) => state.networkStateProperties
  );
  const secondsSinceLastUpdate = useStore(
    (state) => state.secondsSinceLastUpdate
  );
  const loadedNodePropertiesCount = useStore(
    (state) => state.loadedNodePropertiesCount
  );
  const setLoadedNodePropertiesCount = useStore(
    (state) => state.setLoadedNodePropertiesCount
  );

  // Local state
  const [sensor, setSensor] = useState();
  const [editingProperties, setEditingProperties] = useState([]);
  const [isOnline, setIsOnline] = useState(false);

  // Styles
  const classes = useStyles({
    secondsSinceLastUpdate,
    loadedNodePropertiesCount,
  });

  // Effects
  useEffect(() => {
    const nodeModel = getNodeModel(modelId);
    let properties = [...nodeModel.Properties];
    if (networkStateFetched && networkStateProperties) {
      for (var i = 0; i < properties.length; i++) {
        const statusProp = networkStateProperties.find(
          (p) => p.id === 'n.' + uuid + '.status'
        );
        if (statusProp) {
          setIsOnline(parseInt(statusProp.v) & 0x2);
          break;
        }
      }
    }

    if (nodeSensors && nodeSensors.length > 0) {
      setSensor(nodeSensors[0]);
    }
  }, [uuid, networkStateProperties, networkStateFetched, modelId, nodeSensors]);

  const showProperties = () => {
    const nodeModel = getNodeModel(modelId);

    const handleEditButtonClick = (prop) => {
      if (!editingProperties.includes(prop.name)) {
        const editing = produce(editingProperties, (draft) => {
          draft.push(prop.name);
        });
        setEditingProperties(editing);
      } else {
        const editing = editingProperties.filter((name) => name !== prop.name);
        setEditingProperties(editing);
      }
    };

    if (nodeModel) {
      let properties = [...nodeModel.Properties];
      if (networkStateFetched && networkStateProperties) {
        properties = properties.map((property) => {
          const matchingProp = networkStateProperties.find(
            (p) => p.id === 'n.' + uuid + '.' + property.name
          );
          return matchingProp ? { ...property, ...matchingProp } : property;
        });
      } else {
        properties = properties.map((property) => ({
          ...property,
          v: undefined,
        }));
      }

      const filteredProperties = properties.filter(
        (prop) =>
          isPermissionGranted(Permissions.show_hidden_properties) ||
          !(prop.hasOwnProperty('hidden') && prop.hidden === true)
      );

      if (loadedNodePropertiesCount === 0) {
        setLoadedNodePropertiesCount(
          filteredProperties.filter(
            (prop) => prop.hasOwnProperty('v') && prop.v !== undefined
          ).length
        );
      }

      return filteredProperties.map((prop) => (
        <Box className={classes.monitoring} key={prop.name}>
          <Box className={classes.name}>
            {prop.label ? prop.label : prop.name}
          </Box>
          <Box className={classes.valueContainer}>
            <Box className={classes.value}>
              {!editingProperties.includes(prop.name) && formatProp(prop)}

              {editingProperties.includes(prop.name) &&
                prop.type === 'bool' && (
                  <SavingSwitch nodeUuid={uuid} prop={prop} />
                )}

              {editingProperties.includes(prop.name) &&
                (isPropNumeric(prop) || prop.unit === 'ms') && (
                  <SavingTextField nodeUuid={uuid} prop={prop} />
                )}

              {editingProperties.includes(prop.name) &&
                prop.unit === 'date' && (
                  <SavingDatePicker nodeUuid={uuid} prop={prop} />
                )}
            </Box>
            <Box className={classes.icon}>
              {prop.hasOwnProperty('v') && prop.writable && (
                <IconButton onClick={() => handleEditButtonClick(prop)}>
                  {editingProperties.includes(prop.name) && (
                    <Tooltip title='Close' placement='right'>
                      <CloseIcon
                        color={
                          editingProperties.includes(prop.name)
                            ? 'primary'
                            : 'inherit'
                        }
                      />
                    </Tooltip>
                  )}
                  {!editingProperties.includes(prop.name) && (
                    <EditIcon
                      color={
                        editingProperties.includes(prop.name)
                          ? 'primary'
                          : 'inherit'
                      }
                    />
                  )}
                </IconButton>
              )}
            </Box>
          </Box>
        </Box>
      ));
    }
  };

  return (
    <Box className={classes.root}>
      <ComponentHeader
        name={name}
        tooltip={sensor && sensor.Name}
        caption={`serial: ${formatSerial(uuid)}`}
        icon={
          networkStateFetched ? (
            <IconButton
              disabled={!isOnline}
              style={{ pointerEvents: 'none' }}
              color='primary'
            >
              {isOnline ? <WifiIcon /> : <WifiOffIcon />}
            </IconButton>
          ) : (
            <IconButton
              disabled={true}
              style={{ pointerEvents: 'none' }}
              color='primary'
            >
              <DoDisturbIcon />
            </IconButton>
          )
        }
        menuComponent={<NodeMenu nodeId={id} name={name} />}
      />
      {showProperties()}
    </Box>
  );
};

export default Node;

const useStyles = makeStyles((theme) => ({
  root: (props) => ({
    // Figma
    display: 'flex',
    width: '360px',
    minWidth: '280px',
    maxWidth: '360px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    flexShrink: '0',
    borderRadius: '12px',
    background:
      props.loadedNodePropertiesCount > 0 && props.secondsSinceLastUpdate > 10
        ? '#FF7F7F'
        : 'white',
    boxShadow: '2px 4px 9px 0px rgba(0, 0, 0, 0.06)',
  }),
  icon: {
    display: 'flex',
    height: '10px',
  },
  monitoring: {
    // Figma
    display: 'flex',
    minHeight: '46px',
    padding: '14px 28px',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    alignContent: 'space-between',
    alignSelf: 'stretch',
    flexWrap: 'wrap',
    borderBottom: `1px solid ${theme.palette.secondary.main}`,
    flexDirection: 'row',
  },
  value: {
    textAlign: 'right',
  },
  name: {
    display: 'flex',
    textAlign: 'right',
  },
  valueContainer: {
    width: '100',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
}));
