import React from 'react'

// MUI components
import { Box, Tooltip, Typography, makeStyles } from '@material-ui/core'

const ComponentHeader = ({
  tooltip = '',
  name,
  caption = '',
  icon,
  menuComponent,
}) => {
  // Styles
  const classes = useStyles()

  return (
    <Box className={classes.root}>
      <div className={classes.iconContainer}>{icon}</div>
      <Tooltip arrow placement='left' title={tooltip}>
        <div className={classes.textContainer}>
          <Typography variant='body1' className={classes.title}>
            {name}
          </Typography>
          <Typography variant='caption'>{caption}</Typography>
        </div>
      </Tooltip>
      <div className={classes.menuContainer}>{menuComponent}</div>
    </Box>
  )
}

export default React.memo(ComponentHeader)

const useStyles = makeStyles((theme) => ({
  iconContainer: {
    flexShrink: 0,
    marginLeft: '-10px',
  },
  menuContainer: {
    flexShrink: 0,
  },
  root: {
    // Figma
    display: 'flex',
    height: '68px',
    maxWidth: '360px',
    padding: '0px 14px 0px 28px',
    justifyContent: 'space-between',
    alignItems: 'center',
    alignSelf: 'stretch',
    backgroundColor: theme.palette.secondary.main,
  },
  textContainer: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    flexGrow: 4,
  },
  title: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
}))
