import { produce } from 'immer'
import useStore from '../store/store'
import { sendRequest } from '../api/websocketApi'
import getNetworkStructure from './getNetworkStructure'
import getNetworkUpdateFull from '../actions/getNetworkUpdateFull'

const AssignNode = (params) => {
  const setFormError = useStore.getState().setFormError
  const setLoading = useStore.getState().setLoading
  const nodes = useStore.getState().nodes
  const setNodes = useStore.getState().setNodes

  setLoading(true)
  sendRequest(
    {
      endpoint: '/node/assign',
      body: { ...params },
    },
    (data) => {
      const { success, nodeId, uuid, errCode } = data
      const { name } = params
      if (success) {
        const newNodes = produce(nodes, (draft) => {
          draft.push({
            NodeId: nodeId,
            NodeModelId: 1, // TODO
            Uuid: uuid,
            Name: name,
            sensors: [],
          })
        })
        setNodes(newNodes)
        getNetworkStructure()
        getNetworkUpdateFull()
        setLoading(false)
      } else if (!success && errCode) {
        setLoading(false)
        setFormError(errCode)
      }
    }
  )
}

export default AssignNode
