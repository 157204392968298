import React from 'react'
import { Navigate } from 'react-router-dom'

import MainPage from './MainPage'

import api from './../api/api'
import { ws } from './../api/websocketApi'

const reconnect = () => {
  if (ws.isFullyInitialized()) {
    if (!ws.isConnected()) {
      console.log('Connection lost, reconnecting...')
      ws.setShouldReconnectCallbacks(true)
      ws.connect()
    }
  } else {
    // This is needed to solve inifinite loader
    // when app is sent to background when switching from
    // auth to 'main'.
    if (!ws.isConnected()) {
      ws.connect()
    }
  }
}

export default function AuthLoadingPage() {
  const accessToken = localStorage.getItem('accessToken')
  // console.log(`Using access token ${accessToken}`)

  if (!accessToken) {
    return <Navigate to='/login' />
  } else {
    // Make sure Axios uses current token!
    api.defaults.headers.common.Authorization = `Bearer ${accessToken}`

    // Connect to websocket
    reconnect()
  }

  return <MainPage />
}
