import React, { useState } from 'react'

// Libraries
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import { CircularProgress, TextField } from '@material-ui/core'
import * as Yup from 'yup'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import { Formik, Form, Field } from 'formik'
import { Navigate } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'

// API
import api from '../../api/api'

// Store
import useStore from '../../store/store'

const EmailSchema = Yup.object().shape({
  email: Yup.string().required('Required'),
  emailConfirmation: Yup.string()
    .required('Required')
    .oneOf([Yup.ref('email'), null], 'E-mail addresses must match'),
})

export default function RegisterFormPage() {
  // State.
  const formError = useStore((state) => state.formError)
  const email = ''
  const emailConfirmation = ''
  const loading = useStore((state) => state.loading)
  const setFormError = useStore((state) => state.setFormError)
  const setLoading = useStore((state) => state.setLoading)
  const [emailSaved, setEmailSaved] = useState(false)

  const confirm = async ({ email }) => {
    console.log('Calling update-email')
    // Modify state, so the button knows that we're requesting data.
    setLoading(true)

    try {
      // Call API.
      const response = await api.post('/user/update-email', {
        email,
      })

      // Deconstruct response.data.
      const { success, errCode } = response.data

      // Store tokens.
      if (success) {
        // Everything done, so stop loading.
        setLoading(false)
        setEmailSaved(true)
      } else if (!success && errCode) {
        setLoading(false)
        setFormError(errCode)
      }
    } catch (e) {
      console.log(e)
      // Network Error (error code 17)
      if (e.code) setFormError(e.code)
    }
  }

  // Styles.
  const classes = useStyles()

  // If we have successfully saved the e-mail, then redirect to main.
  if (emailSaved) {
    return <Navigate to='/' />
  }

  return (
    <Container component='main' maxWidth='xs'>
      <CssBaseline />
      <div className={classes.paper}>
        <Typography component='h1' variant='h5'>
          E-mail
        </Typography>
        <Formik
          className={classes.form}
          initialValues={{
            email: email,
            emailConfirmation: emailConfirmation,
          }}
          onSubmit={confirm}
          validationSchema={EmailSchema}
          validateOnBlur
          validateOnChange
        >
          {({ values, errors, touched, handleChange, handleBlur }) => (
            <Form>
              <Field
                component={TextField}
                value={values.email}
                disabled={loading}
                error={errors.email && touched.email}
                fullWidth
                helperText={errors.email && touched.email ? errors.email : ''}
                id='email'
                label='E-mail'
                margin='normal'
                name='email'
                onBlur={handleBlur}
                onChange={(value) => {
                  formError && setFormError(null)
                  handleChange(value)
                }}
                size='small'
                type='email'
                variant='outlined'
              />
              <Field
                component={TextField}
                value={values.emailConfirmation}
                disabled={loading}
                error={errors.emailConfirmation && touched.emailConfirmation}
                fullWidth
                helperText={
                  errors.emailConfirmation && touched.emailConfirmation
                    ? errors.emailConfirmation
                    : ''
                }
                id='emailConfirmation'
                label='Repeat e-mail'
                margin='normal'
                name='emailConfirmation'
                onBlur={handleBlur}
                onChange={(value) => {
                  formError && setFormError(null)
                  handleChange(value)
                }}
                size='small'
                type='email'
                variant='outlined'
              />
              {formError && (
                <Typography
                  align='center'
                  className={classes.errorMessage}
                  color='error'
                  variant='body1'
                >
                  Incorrect e-mail address!
                </Typography>
              )}
              <div className={classes.wrapper}>
                <Button
                  color='primary'
                  disabled={loading}
                  fullWidth
                  type='submit'
                  variant='contained'
                >
                  Save
                </Button>
                {loading && (
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                  />
                )}
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Container>
  )
}

const useStyles = makeStyles((theme) => ({
  buttonProgress: {
    color: 'primary',
    left: '50%',
    marginLeft: -12,
    marginTop: -12,
    position: 'absolute',
    top: '50%',
  },
  errorMessage: {
    marginTop: theme.spacing(2),
  },
  form: {
    marginTop: theme.spacing(1),
    width: '100%', // Fix IE 11 issue.
  },
  paper: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(8),
  },
  wrapper: {
    margin: theme.spacing(3, 0, 2),
    position: 'relative',
  },
}))
