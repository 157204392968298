import React, { useEffect, useState } from 'react'

// MUI components
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  TextField,
  makeStyles,
} from '@material-ui/core'
import MoreVertIcon from '@material-ui/icons/MoreVert'

// Store
import useStore from '../store/store'

// Actions
import assignSensor from '../actions/assignSensor'
import unassignSensor from '../actions/unassignSensor'
import unassignNode from '../actions/unassignNode'
import replaceSensor from '../actions/replaceSensor'
import renameNode from '../actions/renameNode'

// Helpers
import isPermissionGranted from '../helpers/isPermissionGranted'

// Consts
import Permissions from '../consts/Permissions'

const NodeMenu = ({ nodeId, name }) => {
  // Styles
  const classes = useStyles()

  // State
  const [addSensorDialogOpen, setAddSensorDialogOpen] = useState(false)
  const [replaceSensorDialogOpen, setReplaceSensorDialogOpen] = useState(false)
  const [removeSensorDialogOpen, setRemoveSensorDialogOpen] = useState(false)
  const [anchor, setAnchor] = useState()
  const [newName, setNewName] = useState(name || '')
  const [newSensor, setNewSensor] = useState()
  const [newSensorName, setNewSensorName] = useState('')
  const [removeDialogOpen, setRemoveDialogOpen] = useState(false)
  const [renameDialogOpen, setRenameDialogOpen] = useState(false)
  const formError = useStore((state) => state.formError)
  const sensorModels = useStore((state) => state.sensorModels)
  const setFormError = useStore((state) => state.setFormError)
  const nodes = useStore((state) => state.nodes)
  const [compatibleSensors, setCompatibleSensors] = useState([])
  const logics = useStore((state) => state.logics)
  const [logicsWarning, setLogicsWarning] = useState('')
  const [replacingSensor, setReplacingSensor] = useState(false)
  const [hasSensors, setHasSensors] = useState(false)

  useEffect(() => {
    // find compatible sensors
    const compatible = []
    if (sensorModels && sensorModels.length > 0) {
      const index = nodes.findIndex((node) => node.NodeId === nodeId)
      if (index !== -1) {
        const node = nodes[index]
        for (let i = 0; i < sensorModels.length; i++) {
          if (
            node.sensorModelIds &&
            node.sensorModelIds.includes(sensorModels[i].SensorModelId)
          ) {
            compatible.push(sensorModels[i])
          }
        }
      }

      if (compatible.length > 0) {
        setCompatibleSensors(compatible)
        setNewSensor(compatible[0])
        setNewSensorName(`My ${compatible[0].Name}`)
      }
    }

    const index = nodes.findIndex((node) => node.NodeId === nodeId)
    setHasSensors(index !== -1 && nodes[index].sensors.length > 0)
  }, [nodeId, nodes, sensorModels])

  const handleClick = (event) => {
    setAnchor(event.currentTarget)
  }

  const handleClose = () => {
    setAnchor(null)
  }

  const handleRenameClick = () => {
    setRenameDialogOpen(true)
    setAnchor(null)
  }

  const handleRenameDialogCancel = () => {
    setRenameDialogOpen(false)
  }

  const handleRenameDialogSave = () => {
    renameNode(nodeId, newName)
    setRenameDialogOpen(false)
  }

  const handleRemoveClick = () => {
    const index = nodes.findIndex((node) => node.NodeId === nodeId)
    if (index !== -1) {
      let nodeUuid = nodes[index].Uuid
      let matches = logics.filter(
        (l) =>
          l.JsonLogic.if.property.includes(nodeUuid) ||
          l.JsonLogic.then.property.includes(nodeUuid)
      )
      let logicNames = matches.map((l) => l.Name)
      if (logicNames.length > 0) {
        setLogicsWarning(
          'It is used in the following Network Logics: ' +
            logicNames.join(', ') +
            '!'
        )
      }
    }

    setRemoveDialogOpen(true)
    setAnchor(null)
  }

  const handleRemoveDialogCancel = () => {
    setLogicsWarning('')
    setRemoveDialogOpen(false)
  }

  const handleAddSensorClick = () => {
    if (hasSensors) {
      setReplaceSensorDialogOpen(true)
    } else {
      setAddSensorDialogOpen(true)
    }
    setAnchor(null)
  }

  const selectSensor = (modelId) => {
    const index = sensorModels.findIndex(
      (sensorModel) => sensorModel.SensorModelId === modelId
    )
    if (index !== -1) {
      setNewSensor(sensorModels[index])
      setNewSensorName(`My ${sensorModels[index].Name}`)
    }
  }

  const handleAddSensorDialogSave = () => {
    if (replacingSensor) {
      replaceSensor(nodeId, 1, newSensor.SensorModelId, newSensorName)
    } else {
      assignSensor(nodeId, newSensor.SensorModelId, newSensorName)
    }
    setAddSensorDialogOpen(false)
    setReplacingSensor(false)
  }

  const handleAddSensorDialogCancel = () => {
    setAddSensorDialogOpen(false)
    setReplacingSensor(false)
  }

  const handleReplaceSensorDialogOK = () => {
    setReplacingSensor(true)
    setReplaceSensorDialogOpen(false)
    setAddSensorDialogOpen(true)
  }

  const handleReplaceSensorDialogCancel = () => {
    setReplaceSensorDialogOpen(false)
  }

  const handleRemoveSensorClick = () => {
    const nodeIndex = nodes.findIndex((node) => node.NodeId === nodeId)
    if (nodeIndex !== -1) {
      let sensorId = nodes[nodeIndex].Uuid + '.1'
      let matches = logics.filter(
        (l) =>
          l.JsonLogic.if.property.includes(sensorId) ||
          l.JsonLogic.then.property.includes(sensorId)
      )
      let logicNames = matches.map((l) => l.Name)
      if (logicNames.length > 0) {
        setLogicsWarning(
          'It is used in the following Network Logics: ' +
            logicNames.join(', ') +
            '!'
        )
      }
    }

    setRemoveSensorDialogOpen(true)
    setAnchor(null)
  }

  const handleRemoveSensorDialogOK = () => {
    unassignSensor(nodeId, 1)
    setRemoveSensorDialogOpen(false)
  }

  const handleRemoveSensorDialogCancel = () => {
    setRemoveSensorDialogOpen(false)
  }

  return (
    <>
      <IconButton
        className={classes.menuButton}
        aria-label='more'
        aria-controls='long-menu'
        aria-haspopup='true'
        onClick={handleClick}
        color='primary'
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id='simple-menu'
        anchorEl={anchor}
        keepMounted
        open={Boolean(anchor)}
        onClose={handleClose}
      >
        {isPermissionGranted(Permissions.sensor_assignment) && (
          <>
            <MenuItem
              onClick={handleAddSensorClick}
              disabled={compatibleSensors.length === 0}
            >
              Add attachment...
            </MenuItem>
            <MenuItem onClick={handleRemoveSensorClick} disabled={!hasSensors}>
              Remove attachment...
            </MenuItem>
          </>
        )}
        <MenuItem onClick={handleRenameClick}>Rename...</MenuItem>
        {isPermissionGranted(Permissions.remove_node) && (
          <MenuItem onClick={handleRemoveClick}>Remove...</MenuItem>
        )}
      </Menu>

      {/* RENAME */}
      <Dialog
        open={renameDialogOpen}
        onClose={handleRenameDialogCancel}
        aria-labelledby='rename-dialog-title'
      >
        <DialogTitle id='rename-dialog-title'>Rename</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            fullWidth
            id='name'
            label='Name'
            margin='dense'
            onChange={(event) => {
              setNewName(event.target.value)
            }}
            type='text'
            value={newName}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleRenameDialogCancel} color='primary'>
            Cancel
          </Button>
          <Button onClick={handleRenameDialogSave} color='primary'>
            Save
          </Button>
        </DialogActions>
      </Dialog>

      {/* ADD SENSOR */}
      {compatibleSensors.length > 0 && (
        <Dialog
          open={addSensorDialogOpen}
          onClose={handleAddSensorDialogCancel}
          aria-labelledby='sensor-dialog-title'
        >
          <DialogTitle id='sensor-dialog-title'>Add attachment</DialogTitle>
          <DialogContent style={{ display: 'flex', flexDirection: 'column' }}>
            <FormControl variant='outlined' className={classes.formControl}>
              <InputLabel id='sensor-label'>Attachment type</InputLabel>
              <Select
                labelId='sensor-label'
                id='new-sensor'
                margin='dense'
                value={newSensor.SensorModelId}
                onChange={(event) => {
                  formError && setFormError(null)
                  selectSensor(event.target.value)
                }}
                label='Attachment type'
              >
                {compatibleSensors.map((sensorModel) => (
                  <MenuItem
                    value={sensorModel.SensorModelId}
                    key={sensorModel.SensorModelId}
                  >
                    {sensorModel.Name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              id='newSensorName'
              label='Name'
              margin='dense'
              onChange={(event) => {
                setNewSensorName(event.target.value)
              }}
              type='text'
              value={newSensorName}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleAddSensorDialogCancel} color='primary'>
              Cancel
            </Button>
            <Button onClick={handleAddSensorDialogSave} color='primary'>
              Save
            </Button>
          </DialogActions>
        </Dialog>
      )}

      {/* REPLACE */}
      <Dialog
        open={replaceSensorDialogOpen}
        onClose={handleReplaceSensorDialogCancel}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>Replace attachment</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            Are you sure you want to replace the current attachment?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleReplaceSensorDialogCancel} color='primary'>
            No
          </Button>
          <Button
            onClick={handleReplaceSensorDialogOK}
            color='primary'
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>

      {/* REMOVE SENSOR */}
      <Dialog
        open={removeSensorDialogOpen}
        onClose={handleRemoveSensorDialogCancel}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>Remove attachment</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            Are you sure you want to remove the attachment? {logicsWarning}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleRemoveSensorDialogCancel} color='primary'>
            No
          </Button>
          <Button
            onClick={handleRemoveSensorDialogOK}
            color='primary'
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>

      {/* REMOVE */}
      <Dialog
        open={removeDialogOpen}
        onClose={handleRemoveDialogCancel}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>Remove node</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            Are you sure you want to remove this node? {logicsWarning}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleRemoveDialogCancel} color='primary'>
            No
          </Button>
          <Button
            onClick={() => unassignNode(nodeId)}
            color='primary'
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default NodeMenu

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 200,
  },
  menuButton: {
    '&:hover': {
      color: theme.palette.primary.light,
      backgroundColor: 'transparent',
    },
  },
}))
