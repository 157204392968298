import React, { useEffect } from 'react'
import useStore from '../store/store'
import * as dayjs from 'dayjs'

// UI components
import { Select, MenuItem } from '@material-ui/core'
import { DatePicker } from 'antd'

// Actions
import updateDataPanel from '../actions/updateDataPanel'

var utc = require('dayjs/plugin/utc')
// import utc from 'dayjs/plugin/utc' // ES 2015

dayjs.extend(utc)

const DashboardsPage = ({ uniqueId, accessToken }) => {
  const dataPanels = useStore((state) => state.dataPanels)
  const setTitle = useStore((state) => state.setTitle)

  const { RangePicker } = DatePicker

  useEffect(() => {
    setTitle('Dashboard')
  }, [setTitle])

  const globalStyle = {
    margin: 0,
    padding: 0,
    overflow: 'hidden',
    width: '100vw',
  }

  document.body.style.margin = globalStyle.margin
  document.body.style.padding = globalStyle.padding
  document.body.style.overflow = globalStyle.overflow
  document.documentElement.style.height = globalStyle.height
  document.documentElement.style.width = globalStyle.width

  const iframeStyle = {
    border: 'none',
    width: '100%',
    height: '100%',
  }

  const timeRanges = [
    { label: 'Custom', value: 'custom', key: 'custom-key' },
    { label: 'Last 5 minutes', value: '5m', key: '5m-key' },
    { label: 'Last 15 minutes', value: '15m', key: '15m-key' },
    { label: 'Last 30 minutes', value: '30m', key: '30m-key' },
    { label: 'Last 1 hour', value: '1h', key: '1h-key' },
    { label: 'Last 3 hours', value: '3h', key: '3h-key' },
    { label: 'Last 6 hours', value: '6h', key: '6h-key' },
    { label: 'Last 12 hours', value: '12h', key: '12h-key' },
    { label: 'Last 24 hours', value: '24h', key: '24h-key' },
    { label: 'Last 2 days', value: '2d', key: '2d-key' },
    { label: 'Last 7 days', value: '7d', key: '7d-key' },
    { label: 'Last 30 days', value: '30d', key: '30d-key' },
    { label: 'Today', value: 'today', key: 'today-key' },
    { label: 'Yesterday', value: 'yesterday', key: 'yesterday-key' },
    { label: '2 days ago', value: '2d-ago', key: '2d-ago-key' },
  ]

  const handleSelectChange = (dataPanelId, timeRange) => {
    const newDataPanelConfiguration = {
      ...dataPanels.find((dp) => dp.DataPanelId === dataPanelId).Configuration,
      timeRange,
      from: null,
      to: null,
    }

    updateDataPanel({
      dataPanelId,
      configuration: newDataPanelConfiguration,
    })
  }

  const handlePickerChange = (dataPanelId) => (date, dateString) => {
    if (!date || date.length < 2) return

    const newDataPanelConfiguration = {
      ...dataPanels.find((dp) => dp.DataPanelId === dataPanelId).Configuration,
      timeRange: null,
      from: date[0].valueOf(),
      to: date[1].valueOf(),
    }

    updateDataPanel({
      dataPanelId,
      configuration: newDataPanelConfiguration,
    })
  }

  const hideLogo = () => {
    var i, frames
    frames = document.getElementsByTagName('iframe')

    if (typeof hideLogo.cssApplied == 'undefined') hideLogo.cssApplied = false

    if (!hideLogo.cssApplied && frames.length > 0) {
      try {
        for (i = 0; i < frames.length; ++i) {
          var cssLink = document.createElement('link')
          cssLink.href = process.env.PUBLIC_URL + '/grafana.css'
          cssLink.rel = 'stylesheet'
          cssLink.type = 'text/css'
          frames[i].contentWindow.document.head.appendChild(cssLink)
        }
        hideLogo.cssApplied = true
      } catch (err) {
        console.warn(err)
      }
      return
    }
    setTimeout(hideLogo, 1000)
  }

  const buildUrl = (dataPanel) => {
    let url = dataPanel.Url
    if (dataPanel.Configuration) {
      if (dataPanel.Configuration.from && dataPanel.Configuration.to) {
        url +=
          '&from=' +
          dayjs(dataPanel.Configuration.from).utc(true).valueOf() +
          '&to=' +
          dayjs(dataPanel.Configuration.to).utc(true).valueOf()
      } else if (dataPanel.Configuration.timeRange) {
        if (dataPanel.Configuration.timeRange === 'today') {
          url += '&from=now/d&to=now' // today so far
        } else if (dataPanel.Configuration.timeRange === 'yesterday') {
          url += '&from=now-1d/d&to=now-1d/d' // yesterday
        } else if (dataPanel.Configuration.timeRange === '2d-ago') {
          url += '&from=now-2d/d&to=now-2d/d' // the day before yesterday
        } else
          url += '&from=now-' + dataPanel.Configuration.timeRange + '&to=now'
      } else {
        // default to last 12 h
        url += '&from=now-12h&to=now'
      }
      url += '&refresh=1m'
      return url
    } else {
      // default to last 12 h
      url += '&from=now-12h&to=now&refresh=1m'
      return url
    }
  }

  const showSelectValue = (dataPanel) => {
    if (dataPanel.Configuration && dataPanel.Configuration.timeRange)
      return dataPanel.Configuration.timeRange
    else if (
      dataPanel.Configuration &&
      dataPanel.Configuration.from &&
      dataPanel.Configuration.to
    )
      return 'custom'
    else return '12h'
  }

  const showPickerValue = (dataPanel) => {
    if (
      dataPanel.Configuration &&
      dataPanel.Configuration.from &&
      dataPanel.Configuration.to
    )
      return [
        dayjs(dataPanel.Configuration.from),
        dayjs(dataPanel.Configuration.to),
      ]
    else return null
  }

  return (
    dataPanels && (
      <div
        style={{
          width: '100%',
          overflow: 'auto',
          marginBottom: '50vh',
        }}
      >
        {dataPanels.map((dataPanel) => (
          <div
            key={dataPanel.DataPanelId}
            style={{
              marginBottom: '25px',
            }}
          >
            <span>Time range: </span>
            <Select
              labelId='demo-simple-select-label'
              id='demo-simple-select'
              value={showSelectValue(dataPanel)}
              label='Time range'
              onChange={(event) =>
                handleSelectChange(dataPanel.DataPanelId, event.target.value)
              }
            >
              {timeRanges.map((timeRange) => (
                <MenuItem
                  value={timeRange.value}
                  key={timeRange.value}
                  disabled={timeRange.value === 'custom'}
                >
                  {timeRange.label}
                </MenuItem>
              ))}
            </Select>
            <RangePicker
              showTime
              onChange={handlePickerChange(dataPanel.DataPanelId)}
              value={showPickerValue(dataPanel)}
            />
            {
              <div
                style={{
                  height: '50vh',
                  width: '100%',
                  overflow: 'hidden',
                  resize: 'vertical',
                  minHeight: '200px',
                }}
              >
                <iframe
                  title={`Dashboard Content ${dataPanel.DataPanelId}`}
                  src={buildUrl(dataPanel)}
                  style={iframeStyle}
                  allowFullScreen
                  key={`iframe-${dataPanel.DataPanelId}`}
                  onLoad={hideLogo}
                ></iframe>
              </div>
            }
          </div>
        ))}
      </div>
    )
  )
}

export default DashboardsPage
