const ErrorCodes = {
  access_denied: 'access_denied',
  already_assigned: 'already_assigned',
  already_confirmed: 'already_confirmed',
  auth_failed: 'auth_failed',
  gateway_not_connected: 'gateway_not_connected',
  gateway_not_found: 'gateway_not_found',
  internal_server_error: 'internal_server_error',
  invalid_param: 'invalid_param',
  invalid_token: 'invalid_token',
  node_not_found: 'node_not_found',
  not_compatible: 'not_compatible',
  phone_exists: 'phone_exists',
  phone_not_found: 'phone_not_found',
  too_many_attempts: 'too_many_attempts',
  rpc_communication_error: 'rpc_communication_error',
  failed_to_create_websocket: 'failed_to_create_websocket',
  err_network: 'ERR_NETWORK',
  other: 'other',
}

export default ErrorCodes
