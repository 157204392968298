import { produce } from 'immer'
import useStore from '../store/store'
import { sendRequest } from '../api/websocketApi'

const ActivateLogic = (userNetworkLogicId, index) => {
  const setFormError = useStore.getState().setFormError
  const setLoading = useStore.getState().setLoading
  const logics = useStore.getState().logics
  const setLogics = useStore.getState().setLogics

  setLoading(true)
  sendRequest(
    {
      endpoint: '/network-logic/activate',
      body: { userNetworkLogicId },
    },
    (data) => {
      const { success, errCode } = data
      if (success) {
        // update store
        const newLogics = produce(logics, (draft) => {
          draft[index].Active = 1
        })
        setLogics(newLogics)
        setLoading(false)
      } else if (!success && errCode) {
        setLoading(false)
        setFormError(errCode)
      }
    }
  )
}

export default ActivateLogic
