import React, { useState, useEffect } from 'react'

// MUI components
import { InputAdornment, TextField, makeStyles } from '@material-ui/core'

// Actions
import setGatewayPropertyValue from '../actions/setGatewayPropertyValue'
import setNodePropertyValue from '../actions/setNodePropertyValue'
import setSensorPropertyValue from '../actions/setSensorPropertyValue'

const SavingTextField = ({
  prop,
  nodeUuid = null,
  index = null,
  gatewayUuid = null,
}) => {
  // Styles
  const classes = useStyles()

  // Local state
  const [value, setValue] = useState(prop.v)
  const [error, setError] = useState(false)
  const [disabled, setDisabled] = useState(false)
  const [timeoutHandle, setTimeoutHandle] = useState(null)

  const afterDot = prop.afterDot || 1

  // Hooks
  useEffect(() => {
    if (disabled) {
      setDisabled(false)

      if (timeoutHandle) {
        clearTimeout(timeoutHandle)
        setTimeoutHandle(null)
      }
    }
    // eslint-disable-next-line
  }, [prop.v])

  useEffect(() => {
    if (disabled) {
      const timeout = setTimeout(() => {
        setDisabled(false)
      }, 1000)
      setTimeoutHandle(timeout)

      return () => {
        clearTimeout(timeoutHandle)
        setTimeoutHandle(null)
      }
    }
    // eslint-disable-next-line
  }, [disabled])

  // Helpers
  const isValid = () => {
    if (
      (!value && value !== 0) || // value empty
      (prop.type === 'percentage' && (value < 0 || value > 100)) // value over limits
    ) {
      setError(true)
      return false
    } else {
      setError(false)
      return true
    }
  }

  // Handlers
  const handleValueChange = (event) => {
    setValue(event.target.value)
  }

  const handleKeyPress = async (event) => {
    // Detect enter press
    if (event.key === 'Enter') {
      // Validate value
      if (isValid()) {
        setDisabled(true)
        const valueToSend =
          prop.type === 'float' ? parseFloat(value) : parseInt(value).toString()
        if (nodeUuid && index) {
          setSensorPropertyValue(nodeUuid, index, prop.name, valueToSend)
        } else if (nodeUuid) {
          setNodePropertyValue(nodeUuid, prop.name, valueToSend)
        } else if (gatewayUuid) {
          setGatewayPropertyValue(gatewayUuid, prop.name, valueToSend)
        }
      }
    }
  }

  return (
    <TextField
      className={classes.input}
      disabled={disabled}
      error={error}
      inputProps={{
        min: ['s64', 's32', 's16', 's8'].indexOf(prop.type) < 0 ? 0 : null,
        max: prop.type === 'percentage' ? 100 : null,
        step: prop.type === 'float' ? afterDot * 0.1 : 1,
        required: true,
      }}
      margin='dense'
      onChange={handleValueChange}
      onKeyPress={handleKeyPress}
      required
      size='small'
      type='number'
      value={value}
      InputProps={{
        endAdornment: (
          <InputAdornment position='end'>{prop.unit || ''}</InputAdornment>
        ),
      }}
    />
  )
}

export default React.memo(SavingTextField)

const useStyles = makeStyles((theme) => ({
  input: {
    width: '15ch',
    marginLeft: theme.spacing(1),
  },
}))
