import sha256 from 'crypto-js/sha256'

import api from '../api/api'
import useStore from '../store/store'

const Login = async ({ iso2, phone, password }) => {
  const setAccessToken = useStore.getState().setAccessToken
  const setFormError = useStore.getState().setFormError
  const setLoading = useStore.getState().setLoading
  const setRefreshToken = useStore.getState().setRefreshToken

  console.log('Calling /security/login')
  // Modify state, so the button knows that we're requesting data.
  setLoading(true)

  try {
    // Call API.
    const response = await api.post('/security/login', {
      iso2,
      phone,
      password: sha256(password).toString(),
    })

    // Deconstruct response.data.
    const { success, errCode, accessToken, refreshToken, permissions } =
      response.data

    // Store tokens.
    if (success) {
      // Store tokens.
      localStorage.setItem('accessToken', accessToken)
      localStorage.setItem('refreshToken', refreshToken)
      localStorage.setItem('permissions', JSON.stringify(permissions))
      localStorage.setItem('iso2', iso2)
      localStorage.setItem('phone', phone)

      // Update Axios header.
      api.defaults.headers.common.Authorization = `Bearer ${accessToken}`

      // Everything done, so stop loading.
      setLoading(false)

      // Save tokens in state. This will redirect to main page.
      setAccessToken(accessToken)
      setRefreshToken(refreshToken)
    } else if (!success && errCode) {
      setLoading(false)
      setFormError(errCode)
    }
  } catch (e) {
    console.log(e)
    // Network Error (error code 17)
    if (e.code) setFormError(e.code)
  }
}

export default Login
