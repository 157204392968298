import React, { useEffect } from 'react'

// Components
import Gateway from '../components/Gateway'

// Store
import useStore from '../store/store'

// MUI components
import { Box, makeStyles } from '@material-ui/core'

export default function GatewayPage() {
  // Styles
  const classes = useStyles()

  const setTitle = useStore((state) => state.setTitle)
  const gateways = useStore((state) => state.gateways)
  const networkStructureFetched = useStore(
    (state) => state.networkStructureFetched
  )
  const setIsAddGatewayFormVisible = useStore(
    (state) => state.setIsAddGatewayFormVisible
  )

  useEffect(() => {
    setTitle('Gateway')
    if (networkStructureFetched && gateways.length === 0) {
      setIsAddGatewayFormVisible(true)
    }

    return () => {
      setIsAddGatewayFormVisible(false)
    }
  }, [gateways, networkStructureFetched, setIsAddGatewayFormVisible, setTitle])

  return (
    <Box className={classes.root}>
      {gateways.map((gateway) => {
        return (
          <Gateway
            id={gateway.GatewayId}
            key={gateway.GatewayId}
            name={gateway.Name}
            uuid={gateway.Uuid}
          />
        )
      })}
    </Box>
  )
}

const useStyles = makeStyles((theme) => ({
  root: { // Figma
    display: 'flex',
    width: '1120px',
    alignItems: 'flex-start',
    alignContent: 'flex-start',
    gap: '40px 20px',
    flexWrap: 'wrap',
  },
}))
