import useStore from '../store/store'
import { sendRequest } from '../api/websocketApi'

const UpdateDataPanel = ({ dataPanelId, configuration }) => {
  const setLoading = useStore.getState().setLoading
  const updateDataPanel = useStore.getState().updateDataPanel
  const setFormError = useStore.getState().setFormError

  setLoading(true)
  const params = { dataPanelId }

  if (configuration) {
    params.configuration = JSON.stringify(configuration)
  }

  sendRequest(
    {
      endpoint: '/data-panel/update',
      body: params,
    },
    (data) => {
      const { success, errCode } = data
      if (success) {
        updateDataPanel({
          dataPanelId,
          configuration,
        })
        setLoading(false)
      } else if (!success && errCode) {
        setLoading(false)
        setFormError(errCode)
      }
    }
  )
}

export default UpdateDataPanel
