import React, { useEffect, useState } from 'react'

// MUI Components
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@material-ui/core'

// Icons
import WarningIcon from '@material-ui/icons/Warning'

// Colors
import yellow from '@material-ui/core/colors/yellow'

// Store
import useStore from '../store/store'

// Actions
import activateLogic from '../actions/activateLogic'
import deactivateLogic from '../actions/deactivateLogic'
import deleteLogic from '../actions/deleteLogic'

export default function LogicPage() {
  // Global state
  const setTitle = useStore((state) => state.setTitle)
  const setIsAddLogicFormVisible = useStore(
    (state) => state.setIsAddLogicFormVisible
  )
  const logics = useStore((state) => state.logics)
  const logicToUpdate = useStore((state) => state.logicToUpdate)
  const setLogicToUpdate = useStore((state) => state.setLogicToUpdate)

  // Local state
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const [logicToDelete, setLogicToDelete] = useState()

  const nodes = useStore.getState().nodes

  useEffect(() => {
    setTitle('Logic')
    setIsAddLogicFormVisible(true)

    return () => {
      setIsAddLogicFormVisible(false)
    }
  }, [setIsAddLogicFormVisible, setTitle])

  const isCoherent = (logic) => {
    return (
      parentComponentExists(logic.JsonLogic.if.property) &&
      parentComponentExists(logic.JsonLogic.then.property)
    )
  }

  const parentComponentExists = (property) => {
    // trim off the name
    let component = property.split('.')
    if (component[0] === 's') {
      // sensor
      let nodeUuid = component[1]
      let sensorIdx = component[2]
      const nodeIndex = nodes.findIndex((node) => node.Uuid === nodeUuid)
      if (nodeIndex !== -1) {
        const sensorIndex = nodes[nodeIndex].sensors.findIndex(
          (sensor) => sensor.Index === parseInt(sensorIdx)
        )
        if (sensorIndex !== -1) {
          return true
        }
      }
    } else if (component[0] === 'n') {
      // node
      let nodeUuid = component[1]
      let index = nodes.findIndex((node) => node.Uuid === nodeUuid)
      if (index !== -1) {
        return true
      }
    }
    return false
  }

  const handleActiveChange = (logic, index) => {
    if (logic.Active === 1) {
      deactivateLogic(logic.UserNetworkLogicId, index)
    } else {
      activateLogic(logic.UserNetworkLogicId, index)
    }
  }

  const handleEditClick = (logic) => {
    setLogicToUpdate(logic)
  }

  const handleDeleteClick = (logicId) => {
    setLogicToDelete(logicId)
    setDeleteDialogOpen(true)
  }

  const handleDeleteDialogCancel = () => {
    setLogicToDelete(null)
    setDeleteDialogOpen(false)
  }

  const handleDeleteDialogConfirm = () => {
    setDeleteDialogOpen(false)
    if (logicToUpdate && logicToUpdate.UserNetworkLogicId === logicToDelete) {
      setLogicToUpdate(null)
    }
    deleteLogic(logicToDelete)
    setLogicToDelete(null)
  }

  return (
    logics && (
      <Grid item xs>
        <TableContainer component={Paper}>
          <Table aria-label='logic table'>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Active</TableCell>
                <TableCell>Description</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {logics.map((logic, index) => (
                <TableRow key={logic.UserNetworkLogicId}>
                  <TableCell component='th' scope='row'>
                    {!isCoherent(logic) && (
                      <Tooltip title='Some components used by this logic have been deleted!'>
                        <WarningIcon style={{ color: yellow[700] }} />
                      </Tooltip>
                    )}
                    {logic.Name}
                  </TableCell>
                  <TableCell>
                    <Switch
                      checked={logic.Active === 1}
                      inputProps={{ 'aria-label': 'primary checkbox' }}
                      onChange={() => handleActiveChange(logic, index)}
                    />
                  </TableCell>
                  <TableCell>{logic.Description}</TableCell>
                  <TableCell align='right'>
                    <Button onClick={() => handleEditClick(logic)}>Edit</Button>
                    <Button
                      onClick={() =>
                        handleDeleteClick(logic.UserNetworkLogicId)
                      }
                    >
                      Delete
                    </Button>

                    {/* DELETE */}
                    <Dialog
                      open={deleteDialogOpen}
                      onClose={handleDeleteDialogCancel}
                      aria-labelledby='alert-dialog-title'
                      aria-describedby='alert-dialog-description'
                    >
                      <DialogTitle id='alert-dialog-title'>
                        Delete logic
                      </DialogTitle>
                      <DialogContent>
                        <DialogContentText id='alert-dialog-description'>
                          Are you sure you want to delete this logic?
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button
                          onClick={handleDeleteDialogCancel}
                          color='primary'
                        >
                          No
                        </Button>
                        <Button
                          onClick={handleDeleteDialogConfirm}
                          color='primary'
                          autoFocus
                        >
                          Yes
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    )
  )
}
