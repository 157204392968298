import { produce } from 'immer'
import useStore from '../store/store'
import { sendRequest } from '../api/websocketApi'

const AssignGateway = (params) => {
  const setFormError = useStore.getState().setFormError
  const setLoading = useStore.getState().setLoading
  const gateways = useStore.getState().gateways
  const setGateways = useStore.getState().setGateways

  setLoading(true)
  sendRequest(
    {
      endpoint: '/gateway/assign',
      body: { ...params },
    },
    (data) => {
      const { success, gatewayId, uuid, errCode } = data
      const { name } = params
      if (success) {
        const newGateways = produce(gateways, (draft) => {
          draft.push({
            GatewayId: gatewayId,
            GatewayModelId: 1, // TODO
            Uuid: uuid,
            Name: name,
          })
        })
        setGateways(newGateways)
        setLoading(false)
      } else if (!success && errCode) {
        setLoading(false)
        setFormError(errCode)
      }
    }
  )
}

export default AssignGateway
