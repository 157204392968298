import { sendRequest } from '../api/websocketApi'
import useStore from '../store/store'

const GetDataPanels = async () => {
  const setLoading = useStore.getState().setLoading
  const setDataPanels = useStore.getState().setDataPanels

  setLoading(true)
  sendRequest(
    {
      endpoint: '/data-panel/get-all',
    },
    (data) => {
      const { success, dataPanels } = data
      if (success) {
        setDataPanels(dataPanels)
      } else {
        const errCode = data.errCode || 'Unknown error'
        console.error('Error fetching data panels:', errCode)
      }
      setLoading(false)
    }
  )
}

export default GetDataPanels
