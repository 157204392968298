import api from '../api/api'
import useStore from '../store/store'

const ResendConfirmationCode = async () => {
  const setFormError = useStore.getState().setFormError
  const setLoading = useStore.getState().setLoading

  console.log('Calling resend-confirmation-code')

  // Modify state, so the button knows that we're requesting data.
  setLoading(true)

  try {
    // Call API.
    const response = await api.post('/security/resend-confirmation-code')

    // Deconstruct response.data.
    const { success, errCode } = response.data

    // Store tokens.
    if (success) {
      // Everything done, so stop loading.
      setLoading(false)
    } else if (!success && errCode) {
      setLoading(false)
      setFormError(errCode)
    }
  } catch (e) {
    console.log(e)
    // Network Error (error code 17)
    if (e.code) setFormError(e.code)
  }
}

export default ResendConfirmationCode
