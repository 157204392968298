import { sendRequest } from '../api/websocketApi'
import useStore from '../store/store'

const GetNetworkStructure = async () => {
  const setLoading = useStore.getState().setLoading
  const setGatewayModels = useStore.getState().setGatewayModels
  const setNodeModels = useStore.getState().setNodeModels
  const setSensorModels = useStore.getState().setSensorModels
  const setGateways = useStore.getState().setGateways
  const setNodes = useStore.getState().setNodes
  const setNetworkStructureFetched =
    useStore.getState().setNetworkStructureFetched
  const setFormError = useStore.getState().setFormError

  setLoading(true)
  sendRequest(
    {
      endpoint: '/user/get-network-structure',
    },
    (data) => {
      let {
        success,
        errCode,
        gatewayModels,
        nodeModels,
        sensorModels,
        gateways,
        nodes,
      } = data

      if (success) {
        // Set network structure in state
        setGatewayModels(gatewayModels)
        setNodeModels(nodeModels)
        let gatewaysCopy = gateways.map((gateway) => ({
          ...gateway,
          properties: [],
        }))
        setGateways(gatewaysCopy)
        setNodes(nodes.map((node) => ({ ...node, properties: [] })))
        setSensorModels(sensorModels)

        setNetworkStructureFetched(true)
        setLoading(false)
      } else if (!success && errCode) {
        setLoading(false)
        setFormError(errCode)
      }
    }
  )
}

export default GetNetworkStructure
