import React, { useEffect } from 'react'

// Components
import Node from '../components/Node'

// Store
import useStore from '../store/store'

// MUI components
import { Box, makeStyles } from '@material-ui/core'

export default function NodesPage() {
  // Styles
  const classes = useStyles()

  const setTitle = useStore((state) => state.setTitle)
  const nodes = useStore((state) => state.nodes)
  const networkStructureFetched = useStore(
    (state) => state.networkStructureFetched
  )
  const setIsAddNodeFormVisible = useStore(
    (state) => state.setIsAddNodeFormVisible
  )

  useEffect(() => {
    setTitle('Nodes')
    setIsAddNodeFormVisible(true)

    return () => {
      setIsAddNodeFormVisible(false)
    }
  }, [networkStructureFetched, setIsAddNodeFormVisible, setTitle])

  return (
    <Box className={classes.root}>
      {nodes.map((node) => {
        return (
          <Node
            id={node.NodeId}
            key={node.NodeId}
            modelId={node.NodeModelId}
            name={node.Name}
            nodeSensors={node.sensors}
            uuid={node.Uuid}
          />
        )
      })}
    </Box>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    // Figma
    display: 'flex',
    width: '1120px',
    alignItems: 'flex-start',
    alignContent: 'flex-start',
    gap: '40px 20px',
    flexWrap: 'wrap',
  },
}))
