import { produce } from 'immer'
import useStore from '../store/store'
import { sendRequest } from '../api/websocketApi'

const UnassignGateway = (gatewayId) => {
  const setFormError = useStore.getState().setFormError
  const setLoading = useStore.getState().setLoading
  const gateways = useStore.getState().gateways
  const setGateways = useStore.getState().setGateways

  setLoading(true)
  sendRequest(
    {
      endpoint: '/gateway/unassign',
      body: { gatewayId },
    },
    (data) => {
      const { success, errCode } = data
      if (success) {
        // TODO handle multiple gateways
        const newGateways = produce(gateways, (draft) => {
          draft.pop()
        })
        setGateways(newGateways)
        setLoading(false)
      } else if (!success && errCode) {
        setLoading(false)
        setFormError(errCode)
      }
    }
  )
}

export default UnassignGateway
