import React, { useState, useEffect } from 'react'

// MUI components
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'

// Actions
import setGatewayPropertyValue from '../actions/setGatewayPropertyValue'
import setNodePropertyValue from '../actions/setNodePropertyValue'
import setSensorPropertyValue from '../actions/setSensorPropertyValue'

const SavingDateTimePicker = ({
  prop,
  nodeUuid = null,
  index = null,
  gatewayUuid = null,
}) => {
  // Local state
  const [disabled, setDisabled] = useState(false)
  const [timeoutHandle, setTimeoutHandle] = useState(null)
  const [selectedDate, setSelectedDate] = useState(
    prop.v ? new Date(prop.v) : new Date()
  )

  useEffect(() => {
    if (disabled) {
      setDisabled(false)

      if (timeoutHandle) {
        clearTimeout(timeoutHandle)
        setTimeoutHandle(null)
      }
    }
    // eslint-disable-next-line
  }, [prop.v])

  useEffect(() => {
    if (disabled) {
      const timeout = setTimeout(() => {
        setDisabled(false)
      }, 1000)
      setTimeoutHandle(timeout)

      return () => {
        clearTimeout(timeoutHandle)
        setTimeoutHandle(null)
      }
    }
    // eslint-disable-next-line
  }, [disabled])

  // Handlers.
  const handleChange = (date) => {
    setSelectedDate(date)
    setDisabled(true)

    if (nodeUuid && index) {
      setSensorPropertyValue(nodeUuid, index, prop.name, date.format())
    } else if (nodeUuid) {
      setNodePropertyValue(nodeUuid, prop.name, date.format())
    } else if (gatewayUuid) {
      setGatewayPropertyValue(gatewayUuid, prop.name, date.format())
    }
  }

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <DateTimePicker
        disabled={disabled}
        format='DD/MM/YYYY hh:mm a'
        margin='dense'
        onChange={handleChange}
        value={selectedDate}
      />
    </MuiPickersUtilsProvider>
  )
}

export default React.memo(SavingDateTimePicker)
