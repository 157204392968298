import { sendRequest } from '../api/websocketApi'
import useStore from '../store/store'

const GetLogics = async () => {
  const setLoading = useStore.getState().setLoading
  const setLogics = useStore.getState().setLogics
  const setFormError = useStore.getState().setFormError

  setLoading(true)
  sendRequest(
    {
      endpoint: '/network-logic/get-all',
    },
    (data) => {
      const { success, errCode, logics } = data
      if (success) {
        setLogics(logics)
        setLoading(false)
      } else if (!success && errCode) {
        setLoading(false)
        setFormError(errCode)
      }
    }
  )
}

export default GetLogics
