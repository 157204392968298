import { produce } from 'immer'
import useStore from '../store/store'
import { sendRequest } from '../api/websocketApi'

const UnassignNode = (nodeId) => {
  const setFormError = useStore.getState().setFormError
  const setLoading = useStore.getState().setLoading
  const nodes = useStore.getState().nodes
  const setNodes = useStore.getState().setNodes

  setLoading(true)
  sendRequest(
    {
      endpoint: '/node/unassign',
      body: { nodeId },
    },
    (data) => {
      const { success, errCode } = data
      if (success) {
        const newNodes = produce(nodes, (draft) => {
          const index = draft.findIndex((node) => node.NodeId === nodeId)
          if (index !== -1) {
            draft.splice(index, 1)
          }
        })
        setNodes(newNodes)
        setLoading(false)
      } else if (!success && errCode) {
        setLoading(false)
        setFormError(errCode)
      }
    }
  )
}

export default UnassignNode
