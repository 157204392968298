import api from '../api/api'
import { ws } from '../api/websocketApi'
import useStore from '../store/store'
import unsubscribe from './unsubscribe'

const Logout = async () => {
  const setAccessToken = useStore.getState().setAccessToken
  const setRefreshToken = useStore.getState().setRefreshToken
  const setGatewayModels = useStore.getState().setGatewayModels
  const setNodeModels = useStore.getState().setNodeModels
  const setSensorModels = useStore.getState().setSensorModels
  const setGateways = useStore.getState().setGateways
  const setNodes = useStore.getState().setNodes
  const setNetworkStructureFetched =
    useStore.getState().setNetworkStructureFetched
  //const setFormError = useStore((state) => state.setFormError)

  console.log('Calling /security/logout')

  try {
    unsubscribe()

    // Close WebSocket connection!
    ws.closeClient()

    // Call API.
    const response = await api.post('/security/logout')

    // Deconstruct response.data.
    const { success } = response.data

    if (success) {
      // Clear local storage
      localStorage.removeItem('accessToken')
      localStorage.removeItem('refreshToken')
      localStorage.removeItem('permissions')

      // Update Axios header.
      api.defaults.headers.common.Authorization = `Bearer ${null}`

      // Remove tokens
      setAccessToken(null)
      setRefreshToken(null)

      setNetworkStructureFetched(false)
      setGatewayModels([])
      setNodeModels([])
      setGateways([])
      setNodes([])
      setSensorModels([])

      // Reload page
      window.location.reload(false)
    }
  } catch (e) {
    console.log(e)
    // Network Error (error code 17)
    //if (e.code) setFormError(e.code)
  }
}

export default Logout
