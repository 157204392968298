import ErrorCodes from '../consts/ErrorCodes'
import React from 'react'
import Urls from '../consts/Urls'
import axios from 'axios'
import { jwtDecode } from "jwt-decode";
import { Navigate } from 'react-router-dom'

// Store
import useStore from '../store/store'

const api = axios.create({
  baseURL: Urls.baseHttpUrl,
})

const setFormError = useStore.getState().setFormError
const setLoading = useStore.getState().setLoading

// Intercept response and check for errors.
api.interceptors.response.use(
  async (response) => {
    const {
      data: { success, errCode },
    } = response
    if (!success && errCode === ErrorCodes.invalid_token) {
      console.log('Received invalid_token error!')

      // Failed to refresh access token.
      if (response.config.url === '/security/refresh-access-token') {
        console.log('Failed to refresh access token.')
        return Promise.reject(new Error('Failed to refresh access token.'))
      }

      const refreshToken = await localStorage.getItem('refreshToken')

      if (refreshToken) {
        // Check if refresh token is still valid.
        // Decode refreshToken.
        const decodedRefreshToken = jwtDecode(refreshToken)

        // If refresh token is not expired then refresh main token.
        const secondsSinceEpoch = Math.floor(Date.now() / 1000)
        if (decodedRefreshToken.exp < secondsSinceEpoch) {
          // Remove stored tokens and data.
          cleanUp()

          return Promise.reject(new Error('Refresh token is expired'))
        }

        // Save config in order to run the failed request after successfully refreshing token.
        const failedRequest = response.config

        // Update Axios header to use refresh token instead.
        api.defaults.headers.common.Authorization = `Bearer ${refreshToken}`

        console.log('Fetching new token...')
        return api
          .post('/security/refresh-access-token')
          .then((refreshTokenResponse) => {
            const { success, accessToken, errCode } = refreshTokenResponse.data
            if (success && accessToken && !errCode) {
              console.log(`Received new token: ${accessToken}`)

              // Update Axios header to use accessToken instead of refresh token.
              api.defaults.headers.common.Authorization = `Bearer ${accessToken}`

              // Store new token.
              localStorage.setItem('accessToken', accessToken)

              // Change failed request token to the new one.
              failedRequest.headers.Authorization = `Bearer ${accessToken}`

              // Run queued request.
              return api(failedRequest)
            } else {
              return Promise.reject(
                new Error('Failed to refresh access token.')
              )
            }
          })
          .catch((error) => {
            // Remove stored tokens and data.
            cleanUp()

            return Promise.reject(error)
          })
      } else {
        // Remove stored tokens and data.
        cleanUp()

        return Promise.reject(
          new Error('No refresh token or application id available')
        )
      }
    } else if (
      !success &&
      (errCode === ErrorCodes.auth_failed ||
        errCode === ErrorCodes.access_denied)
    ) {
      console.log(`Received ${errCode}`)

      // Remove stored tokens and data.
      cleanUp()

      setFormError(errCode)

      return Promise.reject(new Error(errCode))
    } else {
      // All went fine.
      return Promise.resolve(response)
    }
  },
  (error) => {
    return Promise.reject(error)
  }
)

const cleanUp = () => {
  // Remove the headers.
  api.defaults.headers.common.Authorization = null

  // Clean storage.
  localStorage.removeItem('accessToken')
  localStorage.removeItem('refreshToken')
  localStorage.removeItem('permissions')

  setLoading(false)

  // Go to login page.
  return <Navigate to='/login' />
}

export default api
