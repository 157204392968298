import React, { useState } from 'react'

import { Formik, Form, Field } from 'formik'
import { Button, Paper, TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import * as Yup from 'yup'

import assignGateway from '../actions/assignGateway'

// Store
import useStore from '../store/store'

const AssignGatewaySchema = Yup.object().shape({
  serial: Yup.string().required('Required'),
  token: Yup.string().required('Required'),
  name: Yup.string().required('Required'),
})

const AddGatewayForm = () => {
  // Styles.
  const classes = useStyles()

  const serial = ''
  const token = ''
  const name = 'MyGateway'

  const loading = useStore((state) => state.loading)
  const formError = useStore((state) => state.formError)
  const setFormError = useStore((state) => state.setFormError)

  const [isFormVisible, setIsFormVisible] = useState(false)

  const onSubmit = async (values, { resetForm }) => {
    assignGateway(values)
    resetForm({})
    setIsFormVisible(false)
  }

  if (isFormVisible) {
    return (
      <Paper square>
        <Formik
          className={classes.form}
          initialValues={{ serial: serial, token: token, name: name }}
          onSubmit={onSubmit}
          validationSchema={AssignGatewaySchema}
          validateOnBlur
          validateOnChange
        >
          {({
            errors,
            handleBlur,
            handleChange,
            setFieldValue,
            touched,
            values,
          }) => (
            <Form>
              <Field
                autoFocus
                className={classes.formButton}
                component={TextField}
                error={errors.serial && touched.serial}
                helperText={
                  errors.serial && touched.serial ? errors.serial : ''
                }
                id='serial'
                label='Serial'
                margin='normal'
                name='serial'
                onBlur={(e) => {
                  const val = (e.target.value || '').replace(/\s+/gi, ' ')
                  setFieldValue('serial', val.trim())
                  handleBlur(e)
                }}
                onChange={(value) => {
                  formError && setFormError(null)
                  handleChange(value)
                }}
                size='small'
                type='text'
                value={values.serial}
                variant='outlined'
              />

              <Field
                className={classes.formButton}
                component={TextField}
                error={errors.token && touched.token}
                helperText={errors.token && touched.token ? errors.token : ''}
                id='token'
                label='Token'
                margin='normal'
                name='token'
                onBlur={(e) => {
                  const val = (e.target.value || '').replace(/\s+/gi, ' ')
                  setFieldValue('token', val.trim())
                  handleBlur(e)
                }}
                onChange={(value) => {
                  formError && setFormError(null)
                  handleChange(value)
                }}
                size='small'
                type='text'
                value={values.token}
                variant='outlined'
              />

              <Field
                className={classes.formButton}
                component={TextField}
                error={errors.name && touched.name}
                helperText={errors.name && touched.name ? errors.name : ''}
                id='name'
                label='Name'
                margin='normal'
                name='name'
                onBlur={(e) => {
                  const val = (e.target.value || '').replace(/\s+/gi, ' ')
                  setFieldValue('name', val.trim())
                  handleBlur(e)
                }}
                onChange={(value) => {
                  formError && setFormError(null)
                  handleChange(value)
                }}
                size='small'
                type='text'
                value={values.name}
                variant='outlined'
              />

              <Button
                className={classes.formButton}
                color='primary'
                disabled={loading}
                type='submit'
                variant='contained'
              >
                Add
              </Button>
              <Button
                className={classes.formButton}
                color='inherit'
                disabled={loading}
                onClick={() => {
                  setIsFormVisible(false)
                  setFormError(null)
                }}
                type='submit'
                variant='contained'
              >
                Cancel
              </Button>
            </Form>
          )}
        </Formik>
      </Paper>
    )
  } else {
    return (
      <Button
        className={classes.addButton}
        color='primary'
        disabled={loading}
        onClick={() => setIsFormVisible(!isFormVisible)}
        type='submit'
        variant='contained'
      >
        Add Gateway
      </Button>
    )
  }
}

const useStyles = makeStyles((theme) => ({
  addButton: {
    float: 'right',
    margin: theme.spacing(2, 1, 2),
  },
  formButton: {
    margin: theme.spacing(2, 1, 2),
    position: 'relative',
  },
  errorMessage: {
    float: 'center',
    marginBottom: theme.spacing(2),
  },
  field: {
    margin: theme.spacing(2, 1, 2),
  },
  form: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: theme.spacing(1),
    width: '100%', // Fix IE 11 issue.
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    marginTop: 15,
  },
  paper: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    marginTop: theme.spacing(8),
    padding: theme.spacing(8),
  },
}))

export default AddGatewayForm
