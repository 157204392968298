import { produce } from 'immer'
import useStore from '../store/store'
import { sendRequest } from '../api/websocketApi'

const ReplaceSensor = (nodeId, index, sensorModelId, name) => {
  const setFormError = useStore.getState().setFormError
  const setLoading = useStore.getState().setLoading
  const nodes = useStore.getState().nodes
  const setNodes = useStore.getState().setNodes

  setLoading(true)
  sendRequest(
    {
      endpoint: '/sensor/unassign',
      body: { nodeId, index },
    },
    (data) => {
      const { success, errCode } = data
      if (success) {
        var newNodes = produce(nodes, (draft) => {
          const nodeIndex = draft.findIndex((node) => node.NodeId === nodeId)
          if (nodeIndex !== -1) {
            const sensorIndex = draft[nodeIndex].sensors.findIndex(
              (sensor) => sensor.Index === index
            )
            if (sensorIndex !== -1) {
              draft[nodeIndex].sensors.splice(sensorIndex, 1)
            }

            const params = { nodeId, sensorModelId }
            if (name) {
              params.name = name
            }

            sendRequest(
              {
                endpoint: '/sensor/assign',
                body: params,
              },
              (data) => {
                const { success, index, errCode } = data
                if (success) {
                  newNodes = produce(newNodes, (draft) => {
                    const nodeIndex = draft.findIndex(
                      (node) => node.NodeId === nodeId
                    )
                    if (index !== -1) {
                      draft[nodeIndex].sensors.push({
                        Index: index,
                        SensorModelId: sensorModelId,
                        Name: name,
                      })
                    }
                  })
                  setNodes(newNodes)
                  setLoading(false)
                } else if (!success && errCode) {
                  setLoading(false)
                  setFormError(errCode)
                }
              }
            )
          }
        })
      } else if (!success && errCode) {
        setLoading(false)
        setFormError(errCode)
      }
    }
  )
}

export default ReplaceSensor
