import { produce } from 'immer'
import useStore from '../store/store'
import { sendRequest } from '../api/websocketApi'

const RenameSensor = (nodeId, index, name) => {
  const setFormError = useStore.getState().setFormError
  const setLoading = useStore.getState().setLoading
  const nodes = useStore.getState().nodes
  const setNodes = useStore.getState().setNodes

  setLoading(true)
  sendRequest(
    {
      endpoint: '/sensor/rename',
      body: { nodeId, index, name },
    },
    (data) => {
      const { success, errCode } = data
      if (success) {
        const newNodes = produce(nodes, (draft) => {
          const nodeIndex = draft.findIndex((node) => node.NodeId === nodeId)
          if (index !== -1) {
            const sensorIndex = draft[nodeIndex].sensors.findIndex(
              (sensor) => sensor.Index === index
            )
            if (sensorIndex !== -1) {
              draft[nodeIndex].sensors[sensorIndex].Name = name
            }
          }
        })
        setNodes(newNodes)
        setLoading(false)
      } else if (!success && errCode) {
        setLoading(false)
        setFormError(errCode)
      }
    }
  )
}

export default RenameSensor
