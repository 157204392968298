import { sendRequest } from '../api/websocketApi'
import useStore from '../store/store'

const GetAPIKey = async () => {
  const setLoading = useStore.getState().setLoading
  const setAPIKey = useStore.getState().setAPIKey
  const setFormError = useStore.getState().setFormError

  setLoading(true)
  sendRequest(
    {
      endpoint: '/user/get-external-api-key',
    },
    (data) => {
      const { success, errCode, key } = data

      if (success && key !== '') {
        setAPIKey(key)
        setLoading(false)
      } else if (!success && errCode) {
        setLoading(false)
        setFormError(errCode)
      }
    }
  )
}

export default GetAPIKey
