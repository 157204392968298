// Store
import useStore from '../store/store'

const GetNodeModel = (nodeModelId) => {
  const nodeModels = useStore.getState().nodeModels
  if (nodeModels && nodeModels.length > 0) {
    const index = nodeModels.findIndex(
      (model) => model.NodeModelId === nodeModelId
    )
    if (index !== -1) {
      return nodeModels[index]
    }
  }
}

export default GetNodeModel
