import { produce } from 'immer'
import useStore from '../store/store'
import { sendRequest } from '../api/websocketApi'

const UpdateLogic = ({
  userNetworkLogicId,
  name,
  description,
  jsonLogic,
  logic,
}) => {
  const setFormError = useStore.getState().setFormError
  const setLoading = useStore.getState().setLoading
  const logics = useStore.getState().logics
  const setLogics = useStore.getState().setLogics

  setLoading(true)
  const params = { userNetworkLogicId }
  if (name) {
    params.name = name
  }
  if (description) {
    params.description = description
  }
  if (logic) {
    params.logic = logic
  }
  if (jsonLogic) {
    params.jsonLogic = jsonLogic
  }

  sendRequest(
    {
      endpoint: '/network-logic/update',
      body: params,
    },
    (data) => {
      const { success, errCode } = data
      if (success) {
        const newLogics = produce(logics, (draft) => {
          const index = draft.findIndex(
            (logic) => logic.UserNetworkLogicId === userNetworkLogicId
          )
          if (index !== -1) {
            draft[index].Name = name
            draft[index].Description = description
            draft[index].JsonLogic = JSON.parse(jsonLogic)
          }
        })
        setLogics(newLogics)
        setLoading(false)
      } else if (!success && errCode) {
        setLoading(false)
        setFormError(errCode)
      }
    }
  )
}

export default UpdateLogic
